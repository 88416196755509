<template>
  <b-modal id="register-modal" :title="translations[14405]" centered dialog-class="modal-dialog-md-center" @hide="handleHideModal">
    <div class="accordion" role="tablist">
      <div v-for="tab in tabList" :key="tab.value" class="accordion-item">
        <button :class="{ collapsed: selectedAuthTab !== tab.value }" class="accordion-button p-2 fw-bolder" @click="toggleTab(tab.value)">
          {{ tab.title }}
        </button>
        <b-collapse :visible="selectedAuthTab === tab.value" accordion="my-accordion">
          <div class="accordion-body p-2" v-if="selectedAuthTab === tab.value">
            <component :is="tab.component" :ref="tab.value" @submit="submitRegister" />
          </div>
        </b-collapse>
      </div>
    </div>
    <template #modal-footer="{ hide }">
      <b-row class="gx-2 w-100" align-v="center">
        <b-col cols="auto">
          <b-button
            id="register-account-btn"
            variant="primary"
            :disabled="!selectedAuthTab"
            :data-active-tab="submitButtonAnalyticsAttribute"
            @click="submitRegister"
          >{{ translations[13732] }}</b-button>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <p class="fs-6 m-0">
            {{ translations[13733] }} <a v-b-modal.login-modal href="#" class="text-aba-primary-800" @click.prevent="hide">{{ translations[13726] }}</a>
          </p>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import RegisterApplicantForm from '@/components/Authorization/RegisterForms/RegisterApplicantForm.vue';
import RegisterCompanyForm from '@/components/Authorization/RegisterForms/RegisterCompanyForm.vue';
import { AppModule, AuthTab } from '@/store/modules/app';

type TabItem = {
  title: string
  value: AuthTab
  component: 'RegisterApplicantForm' | 'RegisterCompanyForm'
};

interface RefWithRegister {
  onRegistration: () => void
}

interface RefsInterface {
  [key: string]: Vue[] & RefWithRegister[]
}

@Component({
  components: {
    RegisterApplicantForm,
    RegisterCompanyForm,
  },
})
export default class RegisterModal extends Vue {
  get tabList(): TabItem[] {
    return [
      {
        title: this.translations[11115],
        value: AuthTab.applicant,
        component: 'RegisterApplicantForm',
      },
      {
        title: this.translations[13937],
        value: AuthTab.firm,
        component: 'RegisterCompanyForm',
      },
    ];
  }

  get translations() {
    return AppModule.translation;
  }

  get selectedAuthTab() {
    return AppModule.selectedAuthTab;
  }

  set selectedAuthTab(val) {
    AppModule.SET_SELECTED_AUTH_TAB(val);
  }

  get submitButtonAnalyticsAttribute() {
    return this.selectedAuthTab ? `${this.selectedAuthTab}-register-tab` : '';
  }

  toggleTab(value: AuthTab) {
    this.selectedAuthTab = value === this.selectedAuthTab ? AuthTab.default : value;
  }

  submitRegister() {
    const refs = this.$refs as RefsInterface;
    if (this.selectedAuthTab && refs[this.selectedAuthTab]) {
      refs[this.selectedAuthTab][0].onRegistration();
    }
  }

  handleHideModal() {
    AppModule.SET_SELECTED_AUTH_TAB(AuthTab.default);
  }
}
</script>
