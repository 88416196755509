<template>
  <div class="py-2 px-md-0 py-md-4">
    <banner-component :img-name="bannerImgSid" title="" class="banner-component">
      <b-row class="w-100" no-gutters>
        <b-col
          v-for="(item, index) in countsData"
          :key="index"
          cols="12"
          :md="countsData.length === 2 ? 6 : 4"
          class="pb-4 px-1"
          :class="[{ 'mb-3': !isHide }]"
        >
          <router-link :to="item.link" class="welcome-count__number d-block display-1 mb-0 text-center timer text-white">
            <ICountUp :delay="1000" :endVal="item.count" :options="options" />
          </router-link>
          <router-link :to="item.link" class="welcome-count__text d-block fs-4 mb-0 text-center text-white">
            <span class="fw-bolder" v-html="item.title"></span>
          </router-link>
        </b-col>
        <b-col v-if="isHide" cols="12" class="px-1 mb-1 mb-sm-0" sm="6" xl>
          <!--          <select-profession :border="true" :lg="true" v-model="selectProfession" />-->
          <select-profession-update v-model="selectProfession" :fixed="true" />
        </b-col>
        <b-col v-if="isHide" cols="12" class="px-1" sm="6" xl>
          <select-location
            :placeholder="isHide ? searchPlaceholder : `${translations[13589]}...`"
            :border="true"
            :lg="true"
            v-model="selectLocation"
          />
        </b-col>
        <b-col v-if="isHide" cols="12" class="px-0 mt-1 mt-xl-0" xl="auto">
          <b-row no-gutters>
            <b-col class="px-1">
              <b-button variant="primary" size="lg" class="w-100" @click="search">{{ translations[13938] }}</b-button>
            </b-col>
            <b-col class="px-1">
              <b-button @click="searchFilter" variant="primary" size="lg" class="w-100 whitespace-nowrap" v-b-toggle.jobs-filter>
                <b-icon icon="sliders" class="d-inline-block d-sm-inline-block d-lg-none"></b-icon>
                <span class="ml-1 d-none d-sm-inline-block">{{ translations[13939] }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </banner-component>
    <b-row>
      <!-- <b-col cols="12" v-if="sid === 'jig'">
        <span class="present text-uppercase font-weight-light">JIG - JOBS IN GERMANY</span>
        <h2 style="font-weight: 600;">
          About Us?
        </h2>
        <p class="m-0 mb-4" style="font-size: 20px">
          JIG (Jobs In Germany) is an organization that provides consulting services on residence and work permits abroad. JIG is the result of 25 years of experience and is at your disposal with its professional staff. JIG is aimed specifically at young people and people who are looking for a career abroad, and offers professional advice to those who want to go this way. With our high-quality approach to service, people can be guided at any stage from their choice of destination abroad and even their next steps.
        </p>
      </b-col>
      <b-col cols="12" v-else-if="sid === 'talentpool'">
        <template v-if="lang === 'uk'">
          <h3 style="font-weight: 800;">
            Ласкаво просимо до бази даних кваліфікованих кадрів JobMATCH
          </h3>
          <p class="m-0 mb-4" style="font-size: 20px; font-weight: 600;">
            Платформа для іноземних кваліфікованих працівників, роботодавців та партнерів програми
          </p>
        </template>
        <template v-else-if="lang === 'en'">
          <h3 style="font-weight: 800;">
            Welcome to the JobMATCH Talent Pool
          </h3>
          <p class="m-0 mb-4" style="font-size: 20px; font-weight: 600;">
            The platform for international skilled workers, employers and program partners
          </p>
        </template>
        <template v-else>
          <h3 style="font-weight: 800;">
            Willkommen im JobMATCH Talentpool
          </h3>
          <p class="m-0 mb-4" style="font-size: 20px; font-weight: 600;">
            Die Plattform für internationale Arbeits- und Fachkräfte, Arbeitgeber und Programmpartner
          </p>
        </template>
      </b-col>
      <b-col cols="12" v-else-if="sid === 'edunet'">
        <template v-if="lang === 'uk'">
          <h3 style="font-weight: 800;">
            Ласкаво просимо до бази даних кваліфікованих кадрів Edunet
          </h3>
          <p class="m-0 mb-4" style="font-size: 20px; font-weight: 600;">
            Платформа для іноземних кваліфікованих працівників, роботодавців та партнерів програми
          </p>
        </template>
        <template v-else-if="lang === 'en'">
          <h3 style="font-weight: 800;">
            Welcome to the Edunet Talent Pool
          </h3>
          <p class="m-0 mb-4" style="font-size: 20px; font-weight: 600;">
            The platform for international skilled workers, employers and program partners
          </p>
        </template>
        <template v-else>
          <h3 style="font-weight: 800;">
            Willkommen im Edunet Talentpool
          </h3>
          <p class="m-0 mb-4" style="font-size: 20px; font-weight: 600;">
            Die Plattform für internationale Arbeits- und Fachkräfte, Arbeitgeber und Programmpartner
          </p>
        </template>
      </b-col>
      <b-col cols="12" v-else>
        <h2 v-html="translations[13940]"></h2>
        <p class="fw-bolder m-0 fs-4 mb-4" v-html="translations[13941]" />
      </b-col> -->
      <b-col>
        <h2 v-html="contentTitle"></h2>
        <p class="m-0 fs-4 mb-4" v-html="contentDescription" />
      </b-col>
    </b-row>

    <jobs-information-box :is-welcome="true" />
    <text-information-box />
    <jobs-filter :is-welcome="true" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import BannerComponent from '@/components/BannerComponent.vue';
import ProfessionsComponent from '@/components/Home/ProfessionsComponent.vue';
import JobsFilter from '@/components/FilterSidebar/JobsFilter.vue';
import NotAuthModal from '@/components/Core/NotAuthModal.vue';
import JobComponent from '@/components/Home/JobComponent.vue';
import JobComponentCluster from '@/components/Home/JobComponentCluster.vue';
import JobsInformationBox from '@/components/Home/JobsInformationBox.vue';
import TextInformationBox from '@/components/Welcome/TextInformationBox.vue';
import SelectProfession from '@/components/Widget/SelectProfession.vue';
import SelectLocation from '@/components/Widget/SelectLocation.vue';
import { ProfessionModule } from '@/store/modules/profession';
import { AppModule, SettingsCounters } from '@/store/modules/app';
import ICountUp from 'vue-countup-v2';
import { JobType } from '@/types/ProfessionType';
import { mixins } from 'vue-class-component';
import LocaleStringMixin from '@/mixins/LocaleStringMixin';
import SelectProfessionUpdate from '@/components/Widget/SelectProfessionUpdate.vue';
import { ThemesModule } from '@/store/modules/themes';

@Component({
  metaInfo(this: any): any {
    return {
      title: this.pageTitles[this.lang] || this.pageTitles.de,
      meta: [{ name: 'description', content: this.pageDescriptions[this.lang] || this.pageDescriptions.de }],
    };
  },
  components: {
    BannerComponent,
    JobsInformationBox,
    ProfessionsComponent,
    JobsFilter,
    NotAuthModal,
    JobComponent,
    SelectProfession,
    SelectLocation,
    ICountUp,
    TextInformationBox,
    JobComponentCluster,
    SelectProfessionUpdate,
  },
})
export default class WelcomeView extends mixins(LocaleStringMixin) {
  get pageTitles() {
    return {
      de: this.title || this.titleSid.de,
      en: this.title || this.titleSid.en,
    };
  }

  pageDescriptions = {
    de: 'Jobs in Österreich suchen & finden ✔ Jobbörse für Österreich ✔ Jobaustausch für Arbeitnehmer & Arbeitgeber ✔ Jetzt informieren & in Österreich arbeiten!',
    en: 'Austria job search ✔ job offers for employers ✔ vacancies in Austria ✔ Search for industries and locations ✔ Find out now and work in Austria!',
  };

  // temp Title
  get titleSid(): { de: string, en: string } {
    if (ThemesModule.sample.title) {
      return ThemesModule.sample.title;
    }
    return {
      de: 'Arbeiten in Österreich – Jobs suchen ✔ | WORK IN AUSTRIA',
      en: 'Working in Austria – find jobs in austria | WORK IN AUSTRIA',
    };
  }

  get bannerImgSid() {
    if (this.sid === 'aba' || this.sid === 'abatest') {
      return 'slide-1';
    }
    return 'welcome_banner';
  }

  get title() {
    return AppModule.settingPortal.html_title;
  }

  get contentTitle() {
    return AppModule.settingPortal.content_title;
  }

  get contentDescription() {
    return AppModule.settingPortal.content_description;
  }

  get searchPlaceholder() {
    return AppModule.settingPortal.search_input_jobs_placeholder || `${this.translations[9503]}...`;
  }

  get headerImage() {
    return AppModule.settingPortal.header_image;
  }

  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get sid() {
    return AppModule.sid;
  }

  get isHide() {
    return !['aba', 'abatest'].includes(this.sid);
  }

  get allCount(): number {
    return Number(AppModule.setting?.all_count || 0);
  }

  get allCountCompany(): number {
    return Number(AppModule.setting?.companies_count || 0);
  }

  get allCountWorkers(): number {
    return Number(AppModule.setting?.applicant_search_tab?.overall_applicants_count || 0);
  }

  get isAlternativeCountTheme() {
    return ['wconat', 't4a'].includes(this.sid);
  }

  get countsData() {
    return [
      {
        count: this.allCountCompany,
        title: this.translations[14670],
        link: '/company',
        order: this.getCounterDataOrder('companies'),
        visibility: this.getCounterDataVisibility('companies'),
      },
      {
        count: this.allCountWorkers,
        title: this.translations[14671],
        link: '/workers',
        order: this.getCounterDataOrder('applicants'),
        visibility: this.getCounterDataVisibility('applicants'),
      },
      {
        count: this.allCount,
        title: this.translations[14672],
        link: '/jobs',
        order: this.getCounterDataOrder('jobs'),
        visibility: this.getCounterDataVisibility('jobs'),
      },
    ]
      .filter((el) => el.visibility)
      .sort((a, b) => a.order - b.order);
  }

  getCounterDataOrder(value: SettingsCounters['name']) {
    return AppModule.settingPortal.counters.findIndex((el) => el.name === value);
  }

  getCounterDataVisibility(value: SettingsCounters['name']) {
    return !!AppModule.settingPortal.counters.find((el) => el.name === value)?.visible;
  }

  options = {
    useEasing: true,
    useGrouping: true,
    separator: AppModule.language === 'en' ? ',' : '.',
    decimal: '.',
    prefix: '',
    suffix: '',
  };

  // search
  get selectProfession() {
    return ProfessionModule.searchJobProfession;
  }

  set selectProfession(value: any) {
    ProfessionModule.SetSearchJobProfession(value);
    ProfessionModule.SetSearchJobProfessionTitle(value);
    ProfessionModule.SetSearch({ obj: 'profession_id', value: value?.map((v: any) => v.id).join(',') || null });
  }

  get selectLocation() {
    return ProfessionModule.searchJobLocation;
  }

  set selectLocation(value: any) {
    ProfessionModule.SetSearchJobLocation(value);
    ProfessionModule.SetSearchJobLocationTitle(value);
    ProfessionModule.SetSearch({ obj: 'location_id', value: value?.id || null });
  }

  searchFilter() {
    ProfessionModule.SetJobType(JobType.search);
  }

  search() {
    ProfessionModule.SET_FILTER_DEFAULT();
    ProfessionModule.SET_SEARCH_DEFAULT();
    ProfessionModule.SET_PARAMS_DEFAULT();
    ProfessionModule.SET_TAGS_DEFAULT();
    ProfessionModule.SetProfessionTitle('');
    ProfessionModule.SetJobType(JobType.search);
    AppModule.SetVisibleMap(false);
    AppModule.SetVisibleJobs(true);
    AppModule.SetVisibleClusterJobs(false);
    AppModule.SET_WELCOME(true);
    this.$router.push({ name: 'Jobs', query: { lang: AppModule.language } });
  }
}
</script>

<style lang="scss" scoped>
.welcome-count {
  &__number {
    @media (max-width: 767.98px) {
      font-size: calc(1.625rem + 1.5vw);
    }
  }
  &__text {
    @media (max-width: 767.98px) {
      font-size: calc(1rem + 0.3vw) !important;
    }
  }
}
</style>
