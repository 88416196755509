export class ActivityManager {
  private static readonly LAST_ACTIVITY_KEY = 'lastActivity';

  private static readonly INACTIVITY_LIMIT = 2 * 60 * 60 * 1000; // Время бездействия в миллисекундах

  private static inactivityTimer: number | null = null;

  private static onLogout: (() => void) | null = null;

  // Обновляем время последней активности и сбрасываем таймер
  static updateLastActivity(): void {
    const now = Date.now();
    localStorage.setItem(ActivityManager.LAST_ACTIVITY_KEY, now.toString());
    this.resetInactivityTimer();

    if (this.onLogout) {
      this.startInactivityTimer(this.onLogout);
    }
  }

  // Проверяем, прошло ли время бездействия
  static checkInactivity(): boolean {
    const lastActivity = localStorage.getItem(ActivityManager.LAST_ACTIVITY_KEY);
    const now = Date.now();

    return !!(lastActivity && now - parseInt(lastActivity, 10) > ActivityManager.INACTIVITY_LIMIT);
  }

  // Запуск таймера
  static startInactivityTimer(onLogout: () => void): void {
    this.onLogout = onLogout;

    // Если последний вход был больше лимита времени, вызываем logout
    if (this.checkInactivity()) {
      onLogout();
    } else {
      // Запускаем таймер на указанное время
      this.inactivityTimer = window.setTimeout(() => {
        if (this.checkInactivity() && this.onLogout) {
          this.onLogout();
        }
      }, this.INACTIVITY_LIMIT);
    }
  }

  // Сброс таймера
  static resetInactivityTimer(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = null;
  }
}
