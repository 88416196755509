<template>
  <div id="captcha-container" class="captcha-widget mb-1 d-flex" style="margin-left: 37px">
    <div id="turnstile-widget" class="cf-turnstile" :data-sitekey="CAPTCHA_SITE_CODE" data-callback="onCaptchaSuccess"></div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Captcha extends Vue {
  @Prop({ default: '' }) value!: string;

  CAPTCHA_SITE_CODE = '0x4AAAAAAA148W95R9zJfaOz';

  onCaptchaSuccess(token: any) {
    this.$emit('change', token);
  }

  get model() {
    return this.value;
  }

  set model(val: string) {
    this.$emit('input', val);
  }

  resetCaptcha() {
    const captchaElement = document.querySelector('.cf-turnstile');
    if (captchaElement) {
      (window as any).turnstile.reset(captchaElement);
    }
  }

  reloadScript() {
    if ((window as any).turnstile && (window as any).turnstile.render) {
      (window as any).turnstile.render('.cf-turnstile', {
        sitekey: this.CAPTCHA_SITE_CODE,
        callback: this.onCaptchaSuccess,
      });
    } else {
      if (!document.querySelector('script[src="https://challenges.cloudflare.com/turnstile/v0/api.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }

      (window as any).onCaptchaSuccess = this.onCaptchaSuccess;
    }
  }

  async mounted() {
    this.model = '';

    this.reloadScript();
  }
}
</script>
<style scoped lang="scss">
.captcha-widget {
  &_content{
    max-width: 200px;
  }
}
</style>
