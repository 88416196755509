<template>
  <div v-if="isSuccessRegister">
    <h5>{{ translations[13898] }}</h5>
    <p v-if="getPasswordRegister">
      {{ translations[10809] }}
    </p>
    <p v-else>
      {{ translations[13899] }}
    </p>
    <p>
      {{ translations[13932] }}
      <a class="fw-bolder" href="#" @click.prevent="reloadTab">
        {{ translations[13933] }}
      </a>
    </p>
  </div>
  <b-form ref="registerForm" v-else autocomplete="new-password" @keydown="handleKeydown">
    <b-alert :show="isErrorsRegister" variant="danger">
      <span v-if="isUsernameExist">{{ translations[232] }}</span>
      <span v-if="isEmailExist">{{ translations[10870] }}</span>
      <span v-if="isPrivacyError">{{ translations[14435] }}</span>
    </b-alert>
    <b-alert :show="isRecaptchaError" variant="danger"> Please confirm the captcha. </b-alert>
    <b-alert :show="isErrorsRegisterPassword" variant="danger">
      {{ translations[240] }}
    </b-alert>
    <b-form-group class="mb-1" id="input-group-1" label-for="applicant-username">
      <b-form-input
        size="lg"
        class="border-0"
        id="applicant-username"
        v-model="register.username"
        type="text"
        autocomplete="new-password"
        :placeholder="translations[11001]"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group class="mb-1" id="input-group-2" label-for="applicant-email">
      <b-form-input
        size="lg"
        class="border-0"
        id="applicant-email"
        v-model="register.email"
        type="email"
        autocomplete="new-password"
        :placeholder="translations[5549]"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="isApplicantField(typeField.visible,'firstname')" class="mb-1" id="input-group-3" label-for="applicant-firstname-register">
      <b-form-input
        size="lg"
        class="border-0"
        id="applicant-firstname-register"
        v-model="register.firstname"
        type="text"
        :placeholder="translations[234]"
        :required="isApplicantField(typeField.mandatory, 'firstname')"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="isApplicantField(typeField.visible,'lastname')" class="mb-1" id="input-group-4" label-for="applicant-lastname-register">
      <b-form-input
        size="lg"
        class="border-0"
        id="applicant-lastname-register"
        v-model="register.lastname"
        type="text"
        :placeholder="translations[235]"
        :required="isApplicantField(typeField.mandatory, 'lastname')"
      ></b-form-input>
    </b-form-group>
    <b-input-group v-if="isShowPasswordRegister" class="mb-1" id="input-group-register" label-for="applicant-password-register">
      <b-form-input
        size="lg"
        class="border-0"
        id="applicant-password-register"
        v-model.trim="register.password"
        :type="passwordFieldType"
        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
        :placeholder="translations[10948]"
        :title="translations[2521]"
        autocomplete="new-password"
        minlength="8"
        required
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="custom-light"  @click="togglePasswordVisibility">
          <b-icon :icon="passwordFieldIcon"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group class="mb-1" v-if="isShowPasswordRegister" id="input-group-register2" label-for="applicant-password-register2">
      <b-form-input
        size="lg"
        class="border-0"
        id="applicant-password-register2"
        v-model.trim="register.password_repeat"
        :type="passwordRepeatFieldType"
        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
        :placeholder="translations[236]"
        :title="translations[2521]"
        autocomplete="new-password"
        minlength="8"
        required
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="custom-light"  @click="togglePasswordRepeatVisibility">
          <b-icon :icon="passwordRepeatFieldIcon"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-row align-v="center">
      <b-col cols="auto">
        <b-form-group class="mb-2" id="input-group-3" label-for="applicant-checkbox">
          <b-form-checkbox id="applicant-checkbox" v-model="isRegisterCheckbox" name="check-button" switch>
            <p v-html="translations[14016]" class="fs-6 mb-0"></p>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group class="mb-1 mt-2"  v-if="isApplicantField(typeField.visible, regField.captcha)">
      <Captcha ref="captcha" @change="updateCaptchaToken" />
    </b-form-group>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { mixins } from 'vue-class-component';
import RegistrationFieldsMixin from '@/mixins/RegistrationFieldsMixin';
import { TypeField, RegistrationField } from '@/types/Login';
import { group } from '@/config/configuration';
import Captcha from '@/components/Widget/Captcha.vue';

@Component({
  components: {
    Captcha,
  },
})
export default class RegisterApplicantForm extends mixins(RegistrationFieldsMixin) {
  isPasswordVisible = false;

  isPasswordRepeatVisible = false;

  isErrorsRegisterPassword = false;

  isSuccessRegister = false;

  isPrivacyError = false;

  get translations() {
    return AppModule.translation;
  }

  get sid() {
    return AppModule.sid;
  }

  get lang() {
    return AppModule.language;
  }

  isRegisterCheckbox = false;

  get isErrorsRegister() {
    return this.isUsernameExist || this.isEmailExist || this.isPrivacyError;
  }

  get getPasswordRegister() {
    return AppModule.setting?.registration_with_password;
  }

  get passwordFieldType() {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  get passwordFieldIcon() {
    return this.isPasswordVisible ? 'eye-slash' : 'eye';
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  get passwordRepeatFieldType() {
    return this.isPasswordRepeatVisible ? 'text' : 'password';
  }

  get passwordRepeatFieldIcon() {
    return this.isPasswordRepeatVisible ? 'eye-slash' : 'eye';
  }

  togglePasswordRepeatVisibility() {
    this.isPasswordRepeatVisible = !this.isPasswordRepeatVisible;
  }

  @Watch('isRegisterCheckbox')
  handler(val: boolean) {
    if (val) this.isPrivacyError = false;
  }

  @Watch('register.password')
  handlerPassword(val: boolean) {
    if (val) this.isErrorsRegisterPassword = false;
  }

  @Watch('register.password_repeat')
  handlerPasswordRepeat(val: boolean) {
    if (val) this.isErrorsRegisterPassword = false;
  }

  isEmailExist = false;

  isUsernameExist = false;

  register = {
    username: '',
    password: '',
    password_repeat: '',
    email: '',
    sid: this.sid,
    lang: this.lang,
    firstname: '',
    lastname: '',
    captcha: '',
  } as any;

  get isShowPasswordRegister() {
    return AppModule.setting?.registration_with_password;
  }

  reloadTab() {
    this.$bvModal.hide('register-modal');
    this.$bvModal.show('login-modal');
  }

  resetErrors() {
    this.isPrivacyError = false;
    this.isEmailExist = false;
    this.isUsernameExist = false;
    this.isRecaptchaError = false;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.$emit('submit');
    }
  }

  updateCaptchaToken(payload: string) {
    this.register.captcha = payload;
    this.isRecaptchaError = false;
  }

  async onLogin() {
    try {
      const login = {
        username: this.register.username,
        password: this.register.password,
        sid: this.sid,
      };
      await UserModule.LoginApplicant(login);
      await this.$router.push({
        name: 'Cabinet',
        query: {
          lang: this.lang,
          ...(UserModule.openAgentPageOnCabinet && { agent: '1' }),
        },
      });
    } catch (error: any) {
      console.error(error);
    }
  }

  async onRegistration() {
    const form = this.$refs.registerForm as HTMLFormElement;
    if (!form.reportValidity()) {
      return;
    }

    this.resetErrors();

    if (!this.isRegisterCheckbox) {
      this.isPrivacyError = true;
      return;
    }

    try {
      const applicantOptionalFields = ['firstname', 'lastname', 'captcha'];

      applicantOptionalFields.forEach((field) => {
        if (!this.isApplicantField(TypeField.visible, field)) {
          delete this.register[field];
        }
      });

      if (!this.isShowPasswordRegister) {
        delete this.register.password;
        delete this.register.password_repeat;
      } else if (this.register.password !== this.register.password_repeat) {
        this.isErrorsRegisterPassword = true;
        return;
      }

      await UserModule.RegisterApplicant(this.register).then((response: any) => {
        if (response.autolog) {
          this.onLogin();
        } else {
          this.isSuccessRegister = true;
        }
      });
    } catch (error: any) {
      if (error.response.data.message === 'email already exists') this.isUsernameExist = true;
      if (error.response.data.message === 'username already exists') this.isUsernameExist = true;
      if (error.response.data.message === 'invalid params') this.isUsernameExist = true;
      if (error.response.data.message === 'Captcha not solved') {
        this.isRecaptchaError = true;
        (this.$refs as any).captcha.resetCaptcha();
      }
    }
  }
}
</script>
